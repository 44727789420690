<template>
    <div class="">

        <!--Contact-->
        <section id="contact-us">
            <div class="contact" 
                :style="'background-image:url('+background+');background-color:#eee;background-size:cover;'">
                <div class="container">
                    <div class="row">

                        <div class="col-md-4 col-sm-4 col-xs-12  contact-text">
                            <div class="agent-p-contact">
                                <div v-if="!pgLoading" class="agetn-contact-2 bottom30">
                                    <p v-if="page"
                                       :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                        <span v-html="($i18n.locale == 'ar') ? page.body_ar : page.body_en"></span>
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 hidden-xs"></div>
                        <div class="col-md-4 col-sm-4 col-xs-12  contact-text">
                            <div class="agent-p-contact">

                                <div class="our-agent-box bottom30">
                                    <h2 :class="($i18n.locale == 'ar') ? 'hacen_algeria text-center' : ''">
                                        {{ $t('app.get_in_touch') }}
                                    </h2>
                                </div>

                                <div v-if="pgLoading" class="agetn-contact-2 bottom30">
                                    <p v-for="(row, idx) in 3" :key="idx" class="webkit-animation webkit-50per-30"></p>
                                </div>

                                <div v-if="!pgLoading" class="agetn-contact-2 bottom30">
                                    <p v-if="phone_number">
                                        <i class="icon-telephone114"></i>
                                        <a :href="'tel:'+phone_number.strip_body">{{ phone_number.strip_body }}</a>
                                    </p>
                                    <p v-if="email_address">
                                        <i class="icon-icons142"></i>
                                        <a :href="'mailto:'+email_address.strip_body">{{ email_address.strip_body }}</a>
                                    </p>
                                   
                                    <p v-if="address" 
                                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                                        style="display: flex">
                                    <i class="icon-icons74"></i>
                                    <span v-html="($i18n.locale == 'ar') ? address.strip_body_ar : address.strip_body"></span>
                                    </p>
                                </div>

                        <!-- Socials -->
                        <ul v-if="pgLoading" class="social_share bottom20">
                            <li v-for="(social, idx) in 5" :key="idx">
                                <a href="javascript:;" class="webkit-animation webkit-30-30"></a>
                            </li>
                        </ul>
                        <ul v-if="!pgLoading" class="social_share bottom20">
                            <li v-for="(social, idx) in socials" :key="idx">
                                <a  v-if="social.provider != 'whatsapp'"
                                    :href="social.provider_url"
                                    :class="social.provider"
                                    target="_blank">
                                    <i :class="'fa fa-'+social.provider"></i>
                                </a>
                            </li>
                        </ul>

                    </div>



                    <!-- Form -->
                    <div class="agent-p-form">
                        <div class="our-agent-box bottom30">
                            <h2 :class="($i18n.locale == 'ar') ? 'hacen_algeria text-center' : ''">
                                {{ $t('app.send_us_a_message') }}
                            </h2>
                        </div>

                        <div class="row">
                            <form @submit.prevent="sendMessage"
                                class="callus"
                                :class="($i18n.locale == 'ar') ? 'rtl' : ''">

                                <div class="col-md-12">
                                    <div class="single-query form-group">
                                        <input
                                            type="text"
                                            v-model="row.name"
                                            :placeholder="$t('app.name')"
                                            class="keyword-input"
                                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                    </div>
                                    <div class="single-query form-group">
                                        <input
                                            type="text"
                                            v-model="row.jobTitle"
                                            :placeholder="$t('app.jobTitle')"
                                            class="keyword-input"
                                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                    </div>
                                    <div class="single-query form-group">
                                        <vue-tel-input
                                            class="keyword-input"
                                            :inputOptions="options"
                                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"
                                            v-model="row.phone_number">
                                        </vue-tel-input>
                                        <!-- <input
                                            type="text"
                                            v-model="row.phone_number"
                                            :placeholder="$t('app.phone_number')"
                                            class="keyword-input"
                                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''"> -->
                                    </div>
                                    <div class="single-query form-group">
                                        <input
                                            type="text"
                                            v-model="row.email_address"
                                            :placeholder="$t('app.email_address')"
                                            class="keyword-input"
                                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                    </div>
                                    <div class="single-query form-group">
                                        <textarea
                                            rows="5"
                                            v-model="row.message"
                                            :placeholder="$t('app.message')"
                                            class="form-control"
                                            :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                        </textarea>
                                    </div>
                                    <button class="btn-blue h45"
                                        :disabled="btnLoading"
                                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                                        <span v-if="btnLoading">
                                            <div class="loaderN loaderN-md mauto mt-15" style='margin-left:-25px'></div>
                                        </span>
                                        <span v-if="!btnLoading">
                                            {{ $t('app.submit_now') }}
                                        </span>
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                    <!-- End Form -->

                        
                        </div>
                    </div>
                    <!--The div element for the map -->
<!-- 
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3454.617744253681!2d31.212841015077302!3d30.019130626723012!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145846e064513165%3A0x70db0dd8633e78fe!2sMemphis%20Tours!5e0!3m2!1sen!2seg!4v1636291716437!5m2!1sen!2seg" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe> -->
                    <iframe :src="map" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

                </div>
            </div>
        </section>
        <!-- Contact End -->

    </div>
</template>


<script>
import iziToast from 'izitoast'
import { VueTelInput } from 'vue-tel-input'

export default {
    name: 'List',
    components: {
        VueTelInput,
    },
    data(){
        return {
            pgLoading: true,
            btnLoading: false,
            background: '',

            jobTitle: '',
            phone_number: '',
            email_address: '',
            address: '',

            page: '',
            socials: [],

            options: {
                placeholder: (this.$i18n.locale == 'ar') ? 'رقم الهاتف' : 'Phone Number'
            }, 

            // form
            row: {
                name: '',
                jobTitle: '',
                email_address: '',
                phone_number: '',
                message: '',
            },

        }
    },
    metaInfo() {
        return {
            title: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_title : this.page.meta.en.meta_title : 'meta_title',
            meta: [
                { 
                    name: 'description', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_description : this.page.meta.en.meta_description : 'meta_description'
                },
                { 
                    name: 'keywords', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_keywords : this.page.meta.en.meta_keywords : 'meta_keywords'
                },
                { 
                    name: 'title', 
                    content: (this.page.meta) ?(this.$i18n.locale == 'ar') ? this.page.meta.ar.meta_title : this.page.meta.en.meta_title : 'meta_title'
                }  
            ]
        }
    },
    mounted() {},
    created() {

        this.fetchData();
        this.fetchMap();
    },
    methods: {
          
        //
        fetchData() {
              this.pgLoading = true;
              this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
              };
              const options = {
                  url: window.baseURL+'/contacts',
                  method: 'GET',
                  data: {},
                  params: {}
              }
              this.axios(options)
              .then(res => {
                this.pgLoading = false;
                console.log(res);
                this.phone_number = res.data.phone_number;
                this.email_address = res.data.email_address;
                this.jobTitle = res.data.jobTitle;
                this.address = res.data.address;

                this.page = res.data.page;
                this.socials = res.data.socials;

                this.background = (this.page.image) ? this.page.image.url : '';
              })
              .catch(() => {})
              .finally(() => {});
        },

        fetchMap() {
            //this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/settings/map',
                method: 'GET',
                data: {}
            }
            this.axios(options)
            .then(map => {
                this.pgLoading = false;
                console.log(map.data.row.strip_body)
                this.map = map.data.row.strip_body;
            })
            .catch(() => {})
            .finally(() => {});
        },

        //
        sendMessage() {
            this.btnLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/contacts',
                method: 'POST',
                data: {
                    name: this.row.name,
                    jobTitle: this.row.jobTitle,
                    email_address: this.row.email_address,
                    phone_number: this.row.phone_number,
                    message: this.row.message
                },
            }
            this.axios(options)
            .then(() => {
                this.btnLoading = false;

                iziToast.success({
                    icon: 'ti-check',
                    title: 'Great job,',
                    message: 'Message Sent Successfully',
                });
                this.$router.push({ path : '/Thankyou' });

            })
            .catch(err => {
               
                this.btnLoading = false;
                iziToast.warning({
                    icon: 'ti-alert',
                    title: 'Wow-man,',
                    message: (err.response) ? err.response.data.message : 'Something went wrong'
                });

            })
            .finally(() => {});
          },

        },
    }
    

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

